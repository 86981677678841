








import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import makeSelectOptions from "@common/util/makeSelectOptions";
import { types } from "@/constants/car";
import BlurredTenant from '@/components/BlurredTenant.vue';

export default Vue.extend({
  components: { BlurredTenant },
  data() {
    return {
      showBlur: userManager.checkRole(["cpoAdmin", "cpoMonitor"], true) && !!userManager.getUserInfo().tenant?.setting?.expDate && userManager.getUserInfo().tenant.setting.expDate < new Date().getTime(),
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "licensePlate",
          "sort-desc": false,
        },
        content: {
          name: "Cars",
          urlEnabled: true,
          search: {
            ext: {
              hidden: true,
            },
          },
          filters: {
            filters: {
              tenantId: userManager.checkRole(["systemAdmin", "admin"]) && {
                type: "XAutocomplete",
                attrs: {
                  label: "Organization",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("tenants", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            orderBy: "name",
                            orderType: "asc",
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              userId: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor"]) && {
                type: "XAutocomplete",
                attrs: {
                  label: "User",
                  required: true,
                  "item-value": "_id",
                  "item-text": "email",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            orderBy: "email",
                            orderType: "asc",
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              licensePlate: {
                attrs: {
                  label: "License Plate",
                },
                rules: {
                  match: {},
                },
              },
              name: {
                attrs: {
                  label: "Car",
                },
                rules: {
                  match: {},
                },
              },
              manufacture: {
                attrs: {
                  label: "Manufacture",
                },
                rules: {
                  match: {},
                },
              },
              model: {
                attrs: {
                  label: "Model",
                },
                rules: {
                  match: {},
                },
              },
              version: {
                attrs: {
                  label: "Version",
                },
                rules: {
                  match: {},
                },
              },
              vin: {
                attrs: {
                  label: "VIN",
                },
                rules: {
                  match: {},
                },
              },
              type: {
                type: "selectMultiple",
                attrs: {
                  label: "Type",
                  items: makeSelectOptions(types),
                },
                rules: {
                  match: {},
                },
              },
            },
          },
          displayFields: {
            licensePlate: {
              text: "License Plate",
              sortable: true,
            },
            name: {
              text: "Car",
              sortable: true,
            },
            manufacture: {
              text: "Manufacture",
              sortable: true,
            },
            model: {
              text: "Model",
              sortable: true,
            },
            version: {
              text: "Version",
              sortable: true,
            },
            vin: {
              text: "VIN",
              sortable: true,
            },
            type: {
              text: "Type",
              sortable: true,
              options: {
                label: true,
                transform: (value) => types[value],
              },
            },
            autochargeAllowed: {
              text: "Autocharge Allowed",
              sortable: true,
              options: {
                boolean: true,
              },
            },
            createdTime: {
              text: "Created Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            updatedTime: {
              text: "Updated Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            user: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor"]) && {
              text: "User",
              options: {
                subProp: "user.email",
                label: true,
                sortBy: "userId",
              },
            },
            tenant: userManager.checkRole(["systemAdmin", "admin"]) && {
              text: "Organization",
              options: {
                subProp: "tenant.name",
                label: true,
                sortBy: "tenantId",
              },
            },
            action: {
              text: "Actions",
            },
          },
          topActionButtons: {
            insert: userManager.checkRole(["user"]) && {},
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["user"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "licensePlate",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor", "user"]) && {},
            },
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("cars", "findAll", options);
              return [items, count];
            },
            async insert(item) {
              return await coreApiClient.call("cars", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "cars",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("cars", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              name: {
                attrs: {
                  label: "Car",
                  required: true,
                },
              },
              licensePlate: {
                attrs: {
                  label: "License Plate",
                  required: true,
                },
              },
              manufacture: {
                attrs: {
                  label: "Manufacture",
                  required: true,
                },
              },
              model: {
                attrs: {
                  label: "Model",
                  required: true,
                },
              },
              version: {
                attrs: {
                  label: "Version",
                  required: true,
                },
              },
              vin: {
                attrs: {
                  label: "VIN",
                  required: true,
                },
              },
              type: {
                type: "select",
                attrs: {
                  label: "Type",
                  required: true,
                  items: makeSelectOptions(types),
                },
              },
              autochargeAllowed: {
                type: "boolean",
                attrs: {
                  label: "Autocharge Allowed",
                },
              },
            },
            insertForm: userManager.checkRole(["user"]) && {
              content: {
                fieldNames: [
                  "licensePlate",
                  "vin",
                  "name",
                  "manufacture",
                  "model",
                  "version",
                  "type",
                  "autochargeAllowed",
                ],
              },
            },
            editForm: userManager.checkRole(["user"]) && {
              content: {
                fieldNames: ["licensePlate", "name", "manufacture", "model", "version", "type", "autochargeAllowed"],
              },
            },
            delete: {
              confirmDisplayField: "licensePlate",
            },
          },
        },
      },
    };
  },
});
